import React, { useContext, Fragment, useEffect } from "react"
import { Formik, Form } from "formik"
import Container from "../Layout/Container"
import FormCheckbox from "elements/Form/FormCheckbox"
import Section from "elements/Section"
import ActionButtons from "elements/ActionButtons"
import { AppContext } from "../../context/AppContext"
import EmployeeDetails from "../PersonalDetails/EmployeeDetails"

import { mechanicsValidationSchema } from "./utils/formData"
import mechanics from "./utils/mechanics.json"
import { navigate } from "gatsby"
import { useEmployees } from "./hooks/useEmployees"

let agreeToMechanicsBlurb =
  "I have read and I agree to the mechanics of the program."

const Mechanics = ({ flow, nextRoute }) => {
  let allEmployees = useEmployees() || []
  let allEmployeesID = allEmployees.map((employee) => employee.employeeId)
  const { state, dispatch } = useContext(AppContext)

  const handleOnSubmit = async (values) => {
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: { ...state.epharmacy, ...values },
      },
    })

    navigate(nextRoute)
  }

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
  }, [])

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
  }, [])

  let backEmployeeId = state.documents.filter(
    (file) => file.type === "EMPLOYEE_BACK"
  )?.[0]

  let frontEmployeeId = state.documents.filter(
    (file) => file.type === "EMPLOYEE_FRONT"
  )?.[0]
  
  return (
    <Container isCentered desktop={6}>
      <div className="content">
        <Formik
          initialValues={{
            orderingFor: state.orderingFor,
            agreeToMechanics: state[flow].agreeToMechanics,
            employeeIDNumber: state.epharmacy.employeeIDNumber,
            documents: state.epharmacy.documents,
          }}
          onSubmit={handleOnSubmit}
          enableReinitialize
          validationSchema={mechanicsValidationSchema(allEmployeesID, state)}
        >
          {({ values, submitCount, setFieldValue, errors, isValid }) => {
            let newValid = state.epharmacy.employeeIDNumber.trim().length > 0 || isValid ? true : false

            return (
              <Form>
                <Section title="Program Mechanics">
                  <ul>
                    {mechanics[0][flow].map((mechanics, index) => (
                      <Fragment key={index}>
                        <li
                          dangerouslySetInnerHTML={{ __html: mechanics }}
                        />
                      </Fragment>
                    ))}
                  </ul>
                  <FormCheckbox
                    name="agreeToMechanics"
                    values={values.agreeToMechanics}
                    options={[agreeToMechanicsBlurb]}
                    isRequired
                  />
                </Section>
                <Section title="Employee Details">
                  <EmployeeDetails
                    submitCount={submitCount}
                    setFieldValue={setFieldValue}
                    errors={errors}
                  />
                </Section>
                <ActionButtons
                  submit={{
                    label: "Get Started",
                    disabled:
                      !values.agreeToMechanics.length ||
                      !newValid ||
                      !backEmployeeId ||
                      !frontEmployeeId,
                    link: nextRoute,
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </Container>
  );
  
}

export default Mechanics
