import React from "react"
import Layout from "components/Layout/Layout"
import Mechanics from "components/Epharmacy/Mechanics"

export default () => {
  return (
    <Layout title="Mechanics" flow="epharmacy" seoTitle="ePharmacy Mechanics">
      <Mechanics flow="epharmacy" nextRoute="/epharmacy/upload" />
    </Layout>
  )
}
